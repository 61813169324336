const Contract = () => {
  return (
    <meta
      http-equiv="refresh"
      content="0; url='https://docs.stone.com.br/contrato-stone/'"
    />
  );
};

export default Contract;
